import { FC } from 'react';
import {
  ParagraphSectionFragment,
  ParagraphSectionWithTextImageFragment,
} from '../../../generated/types';
import { Box, Container, Flex, Img, SimpleGrid } from '@chakra-ui/react';
import { ParagraphRenderer } from '../ParagraphRenderer';
import { HTMLText } from '../../Common/HTMLText';
import { Divider } from '../../Common/Divider';
import { Image } from '../../Common/Image';

interface SectionWithTextImageProps {
  data: ParagraphSectionWithTextImageFragment;
}

export const SectionWithTextImage: FC<SectionWithTextImageProps> = ({
  data,
}) => {
  return (
    <Box
      bg="white"
      pos="relative"
      __css={{
        '.divider': {
          mx: {
            base: 'auto',
            md: '0',
          },
        },
        '.section-text-heading': {
          '.text-align-right': {
            textAlign: {
              base: 'center',
              md: 'right',
            },
          },
        },
        '.section-text-paragraphs': {
          textAlign: {
            base: 'center',
            md: 'inherit',
          },
          '.text-align-right': {
            textAlign: {
              base: 'center',
              md: 'right',
            },
          },
        },
      }}
    >
      <Image
        maxH="100%"
        h="100%"
        objectFit="cover"
        imageStyle={data.image.imageStyle}
        image={data.image}
        loading="lazy"
        hideCopyright
        wrapperProps={{
          pos: 'absolute',
          right: {
            base: 0,
            md: data.imagePosition === 'right' ? '0' : '50%',
          },
          left: {
            base: 0,
            md: data.imagePosition === 'left' ? '0' : '50%',
          },
          bottom: 0,
          top: 0,
        }}
      />
      <Container
        maxW="container.main"
        display="flex"
        justifyContent={
          data.imagePosition === 'right' ? 'flex-start' : 'flex-end'
        }
      >
        <Flex
          py={12}
          width={{
            base: 'none',
            md: '50%',
          }}
          flexDir="column"
          justifyContent="center"
          pos="relative"
        >
          <Img
            display={{
              base: 'none',
              md: 'block',
            }}
            pos="absolute"
            left={data.imagePosition === 'left' ? 'auto' : '100%'}
            right={data.imagePosition === 'left' ? '100%' : 'auto'}
            mr={data.imagePosition === 'left' ? '-1px' : 0}
            top={0}
            border={0}
            src="/image_triangle.svg"
            maxH="100%"
            zIndex={9}
            transform={data.imagePosition === 'left' ? 'scaleX(-1)' : 'none'}
          />

          <Box
            borderRadius="md"
            bg={{
              base: 'rgba(255,255,255,.6)',
              md: 'none',
            }}
          >
            {data.sectionText && (
              <Container maxW="container.main" pt={6}>
                <HTMLText
                  className="section-text-heading"
                  text={data.sectionText}
                  wordBreak="break-word"
                  textAlign={{
                    base: 'center',
                    md: 'inherit',
                  }}
                />

                {data.divider && <Divider my={3} dividerType={data.divider} />}
              </Container>
            )}

            <ParagraphRenderer
              paragraphs={data.content}
              wrapperProps={{
                py: 6,
                className: 'section-text-paragraphs',
              }}
              paragraphWrappers={{
                _all: 'container',
                ParagraphHero: 'none',
              }}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
